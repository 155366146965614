<template>
  <el-dialog
    title="编辑"
    :modelValue="show"
    width="30%"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="70px"
    >
      <el-form-item label="产品图片">
        
        <template #default="">
          <el-image v-if="newForm.product_img" fit="cover" :src="newForm.product_img"></el-image>
          <el-upload
            class="upload-demo"
            action="https://192.168.31.112:91/image/upload_img"
            :on-preview="handlePreview"
            :on-change="uploadImage"
            :file-list="fileList"
            :show-file-list="false"
            list-type="picture"
            :auto-upload="false"

          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                只能上传 jpg/png 文件，且不超过 500kb
              </div>
            </template>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="编号">
        <el-input v-model="newForm.product_no"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input v-model="newForm.price"></el-input>
      </el-form-item>
      <el-form-item label="产品详细">
        <el-input v-model="newForm.product_detail"></el-input>
      </el-form-item>
      <el-form-item label="高度">
        <el-input v-model="newForm.height"></el-input>
      </el-form-item>
      <el-form-item label="内盒">
        <el-input v-model="newForm.inbox"></el-input>
      </el-form-item>
      <el-form-item label="外箱">
        <el-input v-model="newForm.outbox"></el-input>
      </el-form-item>
      <el-form-item label="体积">
        <el-input v-model="newForm.volumn"></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input v-model="newForm.city"></el-input>
      </el-form-item>
      <el-form-item label="港口">
        <el-input v-model="newForm.port"></el-input>
      </el-form-item>

      <el-form-item label="分类">
        <template #default="">
          <el-select
            v-model="newForm.cat_id"
            placeholder="分类"
          >
            <el-option
              v-for="(item,index) in catList"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </template>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          @click="saveEdit"
        >确 定</el-button>
      </span>
    </template>
  
  </el-dialog>
</template>

<script>
import api from "@/api/index";
export default {
  data() {
    return {
        fileList:[],
    
    };
  },
  props: {
    show: {
      type: Boolean,
      defalut: false,
    },
    catList: {
      type: Array,
      defalut: [],
    },
    form: {
      type: Object,
      defalut: () => {
        return {};
      },
    },
  },
  computed: {
    newShow() {
      return this.show;
    },
    newForm() {
      console.log('tag', this.form)
      return this.form;
    },
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    uploadImage(file,fileList){
        console.log(file)
        let fd = new FormData()
        fd.append("file",fileList[0].raw)
        api.imageUpload_img(fd).then((res)=>{
            this.newForm.product_img = res.data.path
            this.fileList = []
        })

    },
    saveEdit() {
      api
        .productUpdate({
          ...this.newForm,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("成功修改");
            this.$emit("close");
          }
          console.log("tag", res);
        });

      // console.log("tag",typeof this.newForm.cat_id,typeof this.catList[0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>