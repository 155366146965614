<template>
  <el-dialog
    title="编辑"
    :modelValue="show"
    width="30%"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="70px"
    >
      <el-form-item label="分类">
        <template #default="">
          <el-select
              filterable
              v-model="newForm.cat_id"
            placeholder="分类"
          >
            <el-option
              v-for="(item,index) in catList"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </template>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          @click="saveEdit"
        >确 定</el-button>
      </span>
    </template>
  
  </el-dialog>
</template>

<script>
import api from "@/api/index";
export default {
  data() {
    return {
        fileList:[],
    
    };
  },
  props: {
    show: {
      type: Boolean,
      defalut: false,
    },
    catList: {
      type: Array,
      defalut: [],
    },
    form: {
      type: Object,
      defalut: () => {
        return {};
      },
    },
  },
  computed: {
    newShow() {
      return this.show;
    },
    newForm() {
      console.log('tag', this.form)
      return this.form;
    },
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    saveEdit() {
      api
        .productUpdate({
          ...this.newForm,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("成功修改");
            this.$emit("close");
          }
          console.log("tag", res);
        });

      // console.log("tag",typeof this.newForm.cat_id,typeof this.catList[0].id);
    },
  },
};
</script>

<style >
.el-select-dropdown__wrap {
  max-height: unset;
}
.el-select-dropdown__list {
  max-height: 600px;
}
</style>