<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="admin_user.username"
          label="用户名"
        ></el-table-column>
        <el-table-column
          prop="action_ip"
          label="ip"
        ></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="操作时间">
          <template #default="scope">{{ timestampToDate(scope.row.create_time*1000) }}</template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.per_page"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑"
      v-model="editVisible"
      width="30%"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="70px"
      >
        <el-form-item label="用户名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveEdit"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        address: "",
        name: "",
        page: 1,
        per_page: 10,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getPage();
  },
  methods: {
    /* eslint-disable */
    //时间格式化
    timestampToDate(timestamp, dateType = "y/m/d h:i:s") {
      dateType = dateType.replace(/\-/g, "/"); //兼容苹果浏览器内核bug
      function getDate(num) {
        num = num + 1;
        return num > 9 ? num : "0" + num;
      }
      if (String(timestamp).length == 10) timestamp = timestamp * 1000;
      let time = new Date(timestamp);
      if (time.getTime() < 1000) return ""; //非日期格式
      let y, m, d, h, i, s;
      y = time.getFullYear();
      m = Number(getDate(time.getMonth()));
      if (Number(m) < 10) m = `0${m}`;
      d = time.getDate();
      if (Number(d) < 10) d = `0${d}`;
      h = time.getHours();
      if (Number(h) < 10) h = `0${h}`;
      i = time.getMinutes();
      if (Number(i) < 10) i = `0${i}`;
      s = time.getSeconds();
      if (Number(s) < 10) s = `0${s}`;
      dateType = dateType.replace(/y/g, y);
      dateType = dateType.replace(/m/g, m);
      dateType = dateType.replace(/d/g, d);
      dateType = dateType.replace(/h/g, h);
      dateType = dateType.replace(/i/g, i);
      dateType = dateType.replace(/s/g, s);
      return dateType;
    },
    /* eslint-enable */
    // 获取 easy-mock 的模拟数据
    getPage() {
      api
        .rbacLog({
          ...this.query,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.pageTotal = res.data.total || 50;
        });
    },
    // 触发搜索按钮
    handleSearch() {
      this.query.page = 1;
      this.getPage();
    },
    // 删除操作
    handleDelete(index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      this.query.page = val;
      this.getPage();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
