<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.keyWord"
          placeholder="分类名"
          class="handle-input mr10"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
        >搜索</el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addVisible=true"
        >增加</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="分类名"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="180"
          align="center"
        >
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 增加弹出框 -->
    <el-dialog
      title="新增分类"
      v-model="addVisible"
      width="30%"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="70px"
      >
        <el-form-item label="名称">
          <el-input v-model="newform.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveAdd"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑"
      v-model="editVisible"
      width="30%"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="70px"
      >
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveEdit"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        address: "",
        name: "",
        keyWord: "",
        pageIndex: 1,
        pageSize: 20    ,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      newform: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getPage();
  },
  methods: {
    //保存新增
    saveAdd() {
      api
        .catAdd({
          name: this.newform.name,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("成功添加");
            this.getPage();
          }
        });
        this.addVisible = false
    },
    // 获取 easy-mock 的模拟数据
    getPage() {
      let data = {
        per_page: this.query.pageSize,
        page: this.query.pageIndex,
      };
      if (this.query.keyWord) {
        data.keyword = this.query.keyWord;
      }
      api.catList(data).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.pageTotal = res.data.total || 50;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex = 1;
      this.getPage();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除

      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then((re) => {
          console.log("tag", re);
          api
            .catDel({
              id: row.id,
            })
            .then((res) => {
              if (res.status === 200) {
                this.$message.success("删除成功");
              }
            });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = row.id;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      api
        .catUpdate({
          id: this.idx,
          name: this.form.name,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message.success(`修改成功`);
            this.getPage();
          }
        });

      this.editVisible = false;
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getPage();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
