<template>
  <div>
    <el-upload
        v-show="false"
        limit="1"
        @change="enterXlsx"
        ref="upload"
        :file-list="uploadXlxs"
        action="https://jsonplaceholder.typicode.com/posts/"
    >

    </el-upload>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-delete"
            class="handle-del mr10"
            @click="delAllSelection"
        >批量删除
        </el-button>
        <el-select
            size="mini"
            v-model="query.catId"
            placeholder="产品分类"
            class="handle-select mr10"
            clearable
        >
          <el-option
              v-for="(item,index) in catList"
              :label="item.name"
              :key="index"
              :value="item.id"
          ></el-option>

        </el-select>
        <el-input
            size="mini"
            v-model="query.keyWord"
            placeholder="编号"
            class="handle-input mr10 m200"
        ></el-input>
        <el-input
            size="mini"
            v-model="query.factoryName"
            placeholder="工厂"
            class="handle-input mr10 m100"
        ></el-input>
        <el-input
            size="mini"
            v-model="query.port"
            placeholder="港口"
            class="handle-input mr10 m100"
        ></el-input>
        <el-input
            size="mini"
            v-model="query.city"
            placeholder="城市"
            class="handle-input mr10 m100"
        ></el-input>
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch(query.keyWord)"
            @keypress.enter="handleSearch(query.keyWord)"
        >搜索
        </el-button>
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="handleClearSearch"
        >清除搜索
        </el-button>
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-mobile"
            @click="createPdf"
        >生成PDF(EN)
        </el-button>
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-document-add"
            @click="createXlsx"
        >生成EXCEL(EN)
        </el-button>

        <el-upload
            class="upload-demo upload-inline-marLeft15"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-change="uploadXlsx"
            :file-list="fileList"
            :show-file-list="false"
            list-type="picture"
            :auto-upload="false"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-document-copy"
          >导入EXCEL
          </el-button>
          <template #tip>

          </template>
        </el-upload>

        <el-link
            type="primary"
            href="/static/bar_code_template.xlsx"
            target="_blank"
        >模板下载
        </el-link>
        <div style="display:inline-block;width:10px"></div>

        <el-button
            @click="priceVisible = true"
            size="mini"
            type="primary"
        >报价单
        </el-button>

      </div>
      <el-table
          v-loading="initTableLoading"
          @selection-change="handleSelectionChange"
          :data="tableData"
          border
          :row-key="getRowKeys"
          class="table"
          ref="initTable"
          header-cell-class-name="table-header"
      >
        <el-table-column
            type="selection"
            width="55"
            align="center"
            reserve-selection
        ></el-table-column>
        <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"
        ></el-table-column>

        <el-table-column
            label="产品图"
            align="center"
        >
          <template #default="scope">
            <el-upload
                class="upload-demo upload-inline"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-change="modiftImage"
                :file-list="fileList"
                :show-file-list="false"
                list-type="picture"
                :auto-upload="false"
                :data="{id:scope.row.id}"
                @click="clickModiftImage(scope.row)"
            >
              <el-image
                  @click="recordRow(scope.row)"
                  class="table-td-thumb"
                  :src="scope.row.product_img"
              ></el-image>
            </el-upload>

          </template>
        </el-table-column>

        <el-table-column
            prop="product_no"
            label="编号"
        ></el-table-column>
        <el-table-column
            prop="product_detail"
            label="产品详细"
        ></el-table-column>
        <el-table-column
            prop="height"
            label="高(cm)"
            width="80"
        ></el-table-column>

        <el-table-column
            prop="inbox"
            label="内盒(PCS)"
            width="90"
        ></el-table-column>
        <el-table-column
            prop="outbox"
            label="外箱(PCS)"
            width="90"
        ></el-table-column>
        <el-table-column
            prop="volumn"
            width="100"
            label="体积(CBM)"
        ></el-table-column>
        <el-table-column
            width="120"
            label="价格">
          <template #default="scope">￥{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column
            prop="city"
            label="城市"
        ></el-table-column>
        <el-table-column
            prop="factory_info"
            label="工厂信息"
        ></el-table-column>
        <el-table-column
            prop="port"
            label="港口"
        >
        </el-table-column>


        <el-table-column
            prop="cat"
            label="分类"
        >
          <template #default="scope">
            {{ scope.row.cat && scope.row.cat.name }}
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleCatEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
          </template>

        </el-table-column>
        <el-table-column
            label="操作"
            width="180"
            align="center"
        >
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
            <el-button
                type="text"
                icon="el-icon-document-add"
                class="red"
                v-if="!scope.row.is_join_cart"
                @click="addReport(scope.$index, scope.row)"
            >加入报价单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <a
        v-show="false"
        ref="download"
        download="template.xlsx"
        href=""
    ></a>
    <!-- 编辑产品 -->
    <editProductList
        :catList="catList"
        :form="form"
        @close="editVisible = false;getPage();"
        :show="editVisible"
    ></editProductList>
    <editProductCat
        :form="form"
        :catList="catList"
        @close="editCatVisible = false;getPage();"
        :show="editCatVisible"
    ></editProductCat>
    <!-- 编辑弹出框 -->
    <!-- 报价单的抽屉 -->
    <el-drawer
        custom-class="overflowY"
        title="我是标题"
        v-model="priceVisible"
        :with-header="false"
        size="80%"
    >
      <div
          class="overflowY"
          style="height:100vh"
      >
        <div style="padding:10px 10px">
          <el-button
              type="primary"
              icon="el-icon-document-add"
              @click="createReportXlsx"
          >生成EXCEL(EN)
          </el-button>

          <el-button
              type="primary"
              icon="el-icon-mobile"
              @click="createReportPdf"
          >生成PDF(EN)
          </el-button>
          <el-button
              type="primary"
              icon="el-icon-delete"
              @click="cancelAllSelect"
          >清除全部
          </el-button>
        </div>

        <el-table
            :data="cartSelection"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
        >
          <el-table-column
              prop="id"
              label="ID"
              width="55"
              align="center"
          ></el-table-column>
          <el-table-column
              label="产品图"
              align="center"
          >
            <template #default="scope">
              <el-upload
                  class="upload-demo upload-inline"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreview"
                  :on-change="modiftImage"
                  :file-list="fileList"
                  :show-file-list="false"
                  list-type="picture"
                  :auto-upload="false"
              >
                <el-image
                    @click="recordRow(scope.row)"
                    class="table-td-thumb"
                    :src="scope.row.product_img"
                ></el-image>
              </el-upload>

            </template>
          </el-table-column>

          <el-table-column
              prop="product_no"
              label="编号"
          ></el-table-column>
          <el-table-column
              prop="price"
              label="价格"
          ></el-table-column>
          <el-table-column
              prop="product_detail"
              label="产品详细"
          ></el-table-column>
          <el-table-column
              prop="height"
              label="高(cm)"
          ></el-table-column>

          <el-table-column
              prop="inbox"
              label="内盒(PCS)"
          ></el-table-column>
          <el-table-column
              prop="outbox"
              label="外箱(PCS)"
          ></el-table-column>
          <el-table-column
              prop="volumn"
              label="体积(CBM)"
          ></el-table-column>
          <el-table-column label="价格">
            <template #default="scope">￥{{ scope.row.price }}</template>
          </el-table-column>
          <el-table-column
              prop="city"
              label="城市"
          ></el-table-column>
          <el-table-column
              prop="factory_info"
              label="工厂信息"
          ></el-table-column>
          <el-table-column
              prop="port"
              label="港口"
          >
          </el-table-column>
          <!-- <el-table-column
          label="状态"
          align="center"
        >
          <template #default="scope">
            <el-tag :type="
                                scope.row.state === '成功'
                                    ? 'success'
                                    : scope.row.state === '失败'
                                    ? 'danger'
                                    : ''
                            ">{{ scope.row.state }}</el-tag>
          </template>
        </el-table-column> -->

          <!-- <el-table-column
          prop="date"
          label="注册时间"
        ></el-table-column> -->

          <el-table-column
              prop="cat"
              label="分类"
          >
            <template #default="scope">{{ scope.row.cat && scope.row.cat.name }}</template>

          </el-table-column>
          <el-table-column
              label="操作"
              width="180"
              align="center"
          >
            <template #default="scope">
              <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDeleteSelect(scope.$index, scope.row)"
              >删除选中
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-drawer>
    <!-- 报价单的抽屉 -->

  </div>
</template>

<script>
import api from "@/api/index";
import editProductList from "@/views/product/components/dialog/editProductList";
import editProductCat from "@/views/product/components/dialog/editProductCat";
import {ElLoading} from 'element-plus';

export default {
  name: "productList",
  data() {
    return {
      query: {
        address: "",
        catId: "",
        name: "",
        pageIndex: 1,
        pageSize: 20,
        keyWord: "",
        city: "",
        port: "",
      },
      cartSelection: [], //报价单的cartSeletion
      initTableLoading: false, //图表的加载
      nowRow: {},
      uploadXlxs: [],
      xlsxUrl: "", //下载的xlsx的路径
      selectedForm: {},
      catList: [],
      tableData: [],
      //报价单的选择
      multipleSelection: [],
      delList: [],
      priceVisible: false,
      editVisible: false,
      editCatVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      img: {
        common: require("@/assets/img/img.jpg"),
      },
    };
  },
  components: {
    editProductList,
    editProductCat,
  },
  created() {
    this.getPage();
    this.getCatList();
  },
  methods: {
    //加入报价单
    addReport(index, row) {
      this.cartSelection.push(row);
      this.tableData[index].is_join_cart = true;
      this.$message({
        type: "success",
        message: "已加入",
      });
    },
    //删除报价单的单个选中
    handleDeleteSelect(index) {
      //删除选中态
      this.$confirm("是否清除选中", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.cartSelection.splice(index, 1);
            this.tableData[index].is_join_cart = false;
            // this.tableData.forEach((item) => {
            //   if (row.id == item.id) {
            //     this.$refs["initTable"].toggleRowSelection(item, false);
            //   }
            // });
          })
          .catch(() => {
          });
    },
    //取消全部报价单的状态
    cancelAllSelect() {
      if (!this.cartSelection.length) {
        return;
      }
      this.$confirm("是否清除全部选中", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.cartSelection = [];
            //取消全部的状态
            this.tableData.forEach((item, index) => {
              this.tableData[index]["is_join_cart"] = false;
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //获取
    getRowKeys(row) {
      return row.id;
    },
    //记录
    isRecordXlsx(row) {
      let isSelect = false;
      this.multipleSelection.forEach((item) => {
        if (item.id == row.id) {
          isSelect = true;
        }
      });
      return isSelect;
    },
    //记录选择的xlsx(废弃)
    // recordSelectXlsx(selection, row) {
    // if (this.multipleSelection.length == 0) {
    //   this.multipleSelection = [row];
    // } else {
    //   let isHaveRepeat = false;
    //   this.multipleSelection.forEach((item) => {
    //     if (item.id == row.id) {
    //       isHaveRepeat = true;
    //     }
    //   });
    //   if (isHaveRepeat) {
    //     let newMultipleSelection = this.multipleSelection.filter((item) => {
    //       if (item.id == row.id) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     });
    //     this.multipleSelection = newMultipleSelection;
    //   } else {
    //     this.multipleSelection = [...this.multipleSelection, row];
    //   }
    // }

    // this.multipleSelection.forEach(item=>{
    //   if(row.id==item.id){
    //     return
    //   }
    //   else{
    //     newMultipleSelection.push(item)
    //   }
    // })

    // this.multipleSelection = newMultipleSelection
    // console.log(this.multipleSelection)
    // },
    recordRow(row) {
      this.form = row;
      console.log("tag", row);
    },
    modiftImage(file, fileList) {
      console.log(this)
      let th = this
      let fd = new FormData();

      let length = fileList.length;
      fd.append("file", fileList[length - 1].raw);
      api.imageUpload_img(fd).then((res) => {
        api
            .productUpdateProductImg({
              product_img: res.data.path,
              id: th.form.id,
            })
            .then((result) => {
              if (result.status == 200) {
                this.$message.success("修改成功");
                this.getPage();
              }
            });
      });
    },
    clickModiftImage(data) {
      this.form = data

    },
    uploadXlsx(file, fileList) {
      let th = this
      let fd = new FormData();
      let length = fileList.length;
      if (fileList.length == 0) {
        return;
      }
      fd.append("file", fileList[length - 1].raw);
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        text: '上传中',
      });
      api.productImport(fd).then(() => {
        this.getPage();
        th.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      }).catch(() => {
        th.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      });
      // api.productImport()
    },
    // 导出pdf
    createPdf() {
      // 要导出的json数据
      const length = this.multipleSelection.length;
      let multipleSelection = [];
      for (let i = 0; i < length; i++) {
        multipleSelection.push(this.multipleSelection[i].id);
      }
      window.open(
          "product/export?id=" +
          JSON.stringify(multipleSelection) +
          "&token=" +
          window.localStorage.getItem("token") +
          "&suffix=pdf"
      );
    },
    // 导出xlsx
    createXlsx() {
      const length = this.multipleSelection.length;
      let multipleSelection = [];
      for (let i = 0; i < length; i++) {
        multipleSelection.push(this.multipleSelection[i].id);
      }
      window.open(
          "product/export?id=" +
          JSON.stringify(multipleSelection) +
          "&token=" +
          window.localStorage.getItem("token")
      );
    },
    // 获取 easy-mock 的模拟数据
    //导出excel
    createReportXlsx() {
      const length = this.cartSelection.length;
      let cartSelection = [];
      for (let i = 0; i < length; i++) {
        cartSelection.push(this.cartSelection[i].id);
      }
      window.open(
          "product/export?id=" +
          JSON.stringify(cartSelection) +
          "&token=" +
          window.localStorage.getItem("token")
      );
    },
    //导出pdf
    createReportPdf() {
      // 要导出的json数据
      const length = this.cartSelection.length;
      let cartSelection = [];
      for (let i = 0; i < length; i++) {
        cartSelection.push(this.cartSelection[i].id);
      }
      window.open(
          "/admin/product/export?id=" +
          JSON.stringify(cartSelection) +
          "&token=" +
          window.localStorage.getItem("token") +
          "&suffix=pdf"
      );
      // this.createPdf();
    },
    getPage() {
      let data = {
        per_page: this.query.pageSize,
        page: this.query.pageIndex,
      };
      if (this.query.keyWord) {
        data.keyword = this.query.keyWord;
      }
      if (this.query.catId) {
        data.cat_id = this.query.catId;
      }
      if (this.query.city) {
        data.city = this.query.city;
      }
      if (this.query.port) {
        data.port = this.query.port;
      }
      if (this.query.factoryName) {
        data.factory_name = this.query.factoryName;
      }
      this.initTableLoading = true;

      api
          .productList({
            ...data,
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
            this.initTableLoading = false;
            //保持选中态
            this.multipleSelection.forEach((item) => {
              this.tableData.forEach((_item) => {
                console.log(item.id === _item.id);
                if (item.id === _item.id) {
                  this.$nextTick(() => {
                    this.$refs["initTable"].toggleRowSelection(_item, true);
                  });
                }
              });
            });
            //保持加入报价单的选中态
            this.cartSelection.forEach((item) => {
              this.tableData.forEach((_item, _index) => {
                if (item.id == _item.id) {
                  this.tableData[_index].is_join_cart = true;
                }
              });
            });
          });
    },
    getCatList() {
      api
          .catList({
            per_page: 200,
            page: 1,
          })
          .then((res) => {
            console.log(res);
            this.catList = res.data.data;
          });
    },
    // 触发搜索按钮
    handleSearch(keyWord) {
      this.query.keyWord = keyWord;
      this.getPage();
    },
    handleClearSearch() {
      this.query.catId = "";
      this.query.keyWord = "";
      this.query.city = "";
      this.query.port = "";
      this.query.factoryName = "";
      this.getPage();
    },
    // 删除操作
    handleDelete(index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
          .then(() => {
            api
                .productDel({
                  id: JSON.stringify([this.tableData[index].id]),
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.$message.success("删除成功");
                    this.getPage();
                  }
                });
          })
          .catch(() => {
            this.$message.success("取消操作");
          });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.$refs["initTable"]);
      // console.log("tag", val);
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let multipleSelection = [];
      for (let i = 0; i < length; i++) {
        multipleSelection.push(this.multipleSelection[i].id);
      }
      // 二次确认删除
      this.$confirm(`确定要删除这${length}条数据吗？`, "提示", {
        type: "warning",
      }).then(() => {
        api
            .productDel({
              id: JSON.stringify(multipleSelection),
            })
            .then((res) => {
              this.getPage();
              if (res.status == 200) {
                this.$message.success("删除成功");
              }
            });
      });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
      this.selectedForm = row;
    },
    handleCatEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editCatVisible = true;
      this.selectedForm = row;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      this.query["pageIndex"] = val;
      this.getPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflowY {
  overflow-y: scroll;
}

.handle-box {
  height: 30px;
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.m100 {
  width: 100px;
}

.m200 {
  width: 200px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 90px;
  height: 60px;
}

.upload-inline-marLeft15 {
  display: inline-block;
  margin-left: 15px;
}

.upload-inline {
  display: inline-block;
}
</style>
